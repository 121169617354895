/*

 Custom colors for Castrolanda organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #0079d0;
$organization_primary_focus: #005b9d;
$organization_primary_disabled: #005b9d4d;

$organization_program_default: #0079d0;

$organization_secondary: #005b9d;
$organization_secondary_focus: #005bcc;

$organization_secondary_transparent: #005b9db3;

$organization_secundary_border: #698cd6;

$organization_secundary_link: #51cdf3;
$organization_secundary_link_hover: #a0e4ff;

$organization_secundary_button: #0079d0;
$organization_secundary_button_focus: #003e6b;
